import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgWhyMarsCover from "../../assets/images/mission/why-mars/why-mars-cover.png"
import imgWhyMarsSectionOne from "../../assets/images/home/home-section-2.png"
import imgMarsSurface from "../../assets/images/home/mars-surface.png"

const WhyMarsPage = () => (
  <Layout lang="ar">
    <SEO title="Why Mars" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgWhyMarsCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-200px", position: "relative"}}>
          <img src={imgWhyMarsSectionOne} className="uk-visible@s" style={{position: "absolute", top: "0", right: "50%", width: "50%", height: "auto", zIndex: "50"}} alt=""/>
        <div className="large-container mt6-ns pb6">
            <div className="standard-container standard-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-50-ns pr5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">

                  لماذا المریخ؟                  <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  لقد سيطر الكوكب الأحمر على الخيال البشري لقرون عدة. كما أننا نعتبر المريخ بعد عقود عدة من الاستكشاف هو أكثر كوكب صالح للحياة في المجموعة الشمسية كونه شبيها ً بالأرض، وعلى الرغم من أن هناك كوكباً آخر أقرب إلى الأرض هو الزهرة إلا أنه لا يمتلك المميزات التي يمتلكها المريخ والتي تجعله صالحاً للسكن مثل:   
                  </p>
                  <ul className="uk-list uk-list-bullet text-dim">
                    <li>
                    احتواء تربته على الماء
                    </li>
                    <li>
                    يمكن للإنسان تحمل مناخه الحالي                      
                    </li>
                    <li>
                    يستقبل ضوءاً كافياً من الشمس لتشغيل الألواح الشمسية                    
                    </li>
                    <li>
                    يمكن للإنسان التكيف مع جاذبيته بسهولة                      
                    </li>
                    <li>
                    لديه مواسم مختلفة، كما أنه يمتلك دورة نهارية وليلية شبيهة بالأرض
                    </li>
                  </ul>
                  <p>
                  يوفر لنا المريخ فرصة لفهم أصل وتطور العملية المناخية والجيولوجية التي جعلت الكوكب الأحمر على حالته الحالية وإذا كانت قابلة للتطبيق على الكواكب الأخرى في مجموعتنا الشمسية، هذا بالطبع إضافة إلى بحثنا عن الدائم عن إمكانية أن يكون المريخ صالحاً للحياة. لقد أظهرت العديد من المهمات السابقة للمريخ أنه كان صالحاً للحياة في الماضي، لذلك فإن فهمنا لتطور طبيعة سطح المريخ وغلافه الجوي ستساعدنا على التنبؤ بمستقبل كوكبنا.
                  </p>
                </div>
              </div>
              <div className="w-100 w-50-ns">
                <img src={imgWhyMarsSectionOne} className="w-90 h-auto uk-hidden@s" alt=""/>
              </div>  
            </div>
           </div>
           
        </div>  
      </section>
    </main>
  </Layout>
)

export default WhyMarsPage
